import { useTranslation } from 'react-i18next'

import ColStaFe from '../../../Assets/Logos/colfono_stafe.png'
import ColChile from '../../../Assets/Logos/colfono_chile.png'
import Stok from '../../../Assets/Logos/Instituto-Stok.png'
import Integrar from '../../../Assets/Logos/Integrar.png'
import Asoaudio from '../../../Assets/Logos/Asoaudio.png'

import Panama from '../../../Assets/Registers/panama.jpg'
import CostaRica from '../../../Assets/Registers/costa_rica.jpg'
import Isp from '../../../Assets/Registers/isp.png'
import Nicaragua from '../../../Assets/Registers/nicaragua.png'
import AnvisaBrasil from '../../../Assets/Registers/anvisa-brasil.jpg'
import Agemed from '../../../Assets/Registers/agemed.jpg'
import Arcsa from '../../../Assets/Registers/arcsa.png'
import Digemid from '../../../Assets/Registers/digemid.png'
import Invima from '../../../Assets/Registers/invima.png'
import Anmat from '../../../Assets/Registers/anmat.svg'
import Zigzag from '../../../Assets/Components/Zigzag'

export default function Registros({
    landing
}) {

    const [t] = useTranslation('global')

    return (
        <section id="registros">
            {landing &&
                <div className='landing_zigzag'>
                    <Zigzag color="#AAF0DF" />
                </div>
            }
            <article className={landing === 'true' ? 'py-5' : 'pt-5'}>
                <h3 className='text-center mb-3'>
                    {t('home.registros.title')}
                </h3>
                {!landing &&
                    <p className='text-center col-6 mx-auto'>
                        {t('home.registros.subtitle')}
                    </p>
                }
                <div className={`d-flex flex-wrap justify-content-around col-12 col-lg-10 mt-4 mx-auto ${landing === 'true' && 'pb-4'}`}>
                    <div className='reg_row col-6 col-md-12'>
                        <div className='regContainer'>
                            <img
                                src={Panama}
                                alt="Ministerio de Salud de Panamá"
                            />
                            <p className='mb-0'>
                                Panamá
                            </p>
                        </div>
                        <div className='regContainer'>
                            <img
                                src={CostaRica}
                                alt="Ministerio de Salud de Costa Rica"
                            />
                            <p className='mb-0'>
                                Costa Rica
                            </p>
                        </div>
                        <div className='regContainer' id='chile_container'>
                            <img
                                src={Isp}
                                alt="Instituto de Salud Pública"
                            />
                            <p className='mb-0'>
                                Chile
                            </p>
                        </div>
                        <div className='regContainer'>
                            <img
                                src={Nicaragua}
                                alt="Ministerio de Salud de Nicaragua"
                            />
                            <p className='mb-0'>
                                Nicaragua
                            </p>
                        </div>
                        <div className='regContainer'>
                            <img
                                src={AnvisaBrasil}
                                alt="Anvisa Brasil"
                            />
                            <p className='mb-0'>
                                Brasil
                            </p>
                        </div>
                    </div>
                    <div className='reg_row col-6 col-md-12'>
                        <div className='regContainer' id='bolivia_container'>
                            <img
                                src={Agemed}
                                alt="Agemed"
                            />
                            <p className='mb-0'>
                                Bolivia
                            </p>
                        </div>
                        <div className='regContainer'>
                            <img
                                src={Arcsa}
                                alt="Arcsa"
                            />
                            <p className='mb-0'>
                                Ecuador
                            </p>
                        </div>
                        <div className='regContainer'>
                            <img
                                src={Digemid}
                                alt="Ministerio de Salud del Perú"
                            />
                            <p className='mb-0'>
                                Perú
                            </p>
                        </div>
                        <div className='regContainer'>
                            <img
                                src={Invima}
                                alt="Invima"
                            />
                            <p className='mb-0'>
                                Colombia
                            </p>
                        </div>
                        <div className='regContainer'>
                            <img
                                src={Anmat}
                                alt="Anmat"
                            />
                            <p className='mb-0'>
                                Argentina
                            </p>
                        </div>
                    </div>
                    {!landing &&
                        <div className='zigzag-green'>
                            <Zigzag color="#AAF0DF" />
                        </div>
                    }
                </div>
            </article>

            {
                !landing &&
                <article className="text-center py-5">
                    <p>{t('home.registros.institutions_title')}</p>
                    <div
                        className="d-flex flex-wrap justify-content-center align-items-center px-5 px-lg-0 mt-4 mx-auto"
                    >
                        <div className='instContainer'>
                            <img
                                src={ColStaFe}
                                alt="Colegio de Fonoaudiólogos de Santa Fe"
                                className='cursor-pointer'
                                onClick={() => window.open('https://www.colfono.org.ar/', '_blank')}
                            />
                        </div>
                        <div className='instContainer my-4'>
                            <img
                                src={ColChile}
                                alt="Colegio de Fonoaudiólogos de Chile"
                                className='cursor-pointer'
                                onClick={() => window.open('https://colfonochile.cl/', '_blank')}
                            />
                        </div>
                        <div className='instContainer lastInst'>
                            <img
                                src={Stok}
                                alt="Colegio de Fonoaudiólogos de Chile"
                            />
                            <small className='mt-1 mb-0'>Instituto Stok S.C.</small>
                        </div>
                        <div className='instContainer lastInst my-4'>
                            <img
                                src={Integrar}
                                alt="Colegio de Fonoaudiólogos de Chile"
                            />
                            <small className='mt-1 mb-0'>Fundación I.N.T.E.G.R.A.R.</small>
                        </div>
                        <div className='instContainer lastInst mt-sm-4 mt-md-0'>
                            <img
                                src={Asoaudio}
                                alt="Colegio de Fonoaudiólogos de Chile"
                                className='cursor-pointer'
                                onClick={() => window.open('https://asoaudio.org.co/', '_blank')}
                            />
                        </div>
                    </div>
                </article>
            }

        </section >
    )
}