import { useTranslation } from 'react-i18next'

import Counter from '../Components/Counter'

import Misc from '../../../Assets/Home/misc.svg'

export default function Mapa() {

    const [t] = useTranslation('global')

    return (
        <section id="mapa">

            <img loading="lazy" src={Misc} className="miscUp" alt="" />

            <article className="col-8 col-sm-4 mapSections">
                <h2 className="text-white fw-bold">
                    {t('home.mapa.title')}
                </h2>

                <p className="mt-4">
                    {t('home.mapa.description')}
                </p>
            </article>

            <article className="d-flex flex-wrap col-6 justify-content-around mapSections">
                <span className="text-white text-center col-md-4 p-2">
                    <h3 className="counterTitle">
                        {<Counter before="+" start={650} end={1000} after="" />}
                    </h3>
                    <span className="counterText">
                        <span className="first">
                            {t('home.mapa.first_child_audiometer')} <br /> {t('home.mapa.first_child_delivered')} <br />
                        </span>
                        <span className="second">
                            {t('home.mapa.first_child_description')}
                        </span>
                    </span>
                </span>
                <span className="text-white text-center col-md-4 p-2">
                    <h3 className="counterTitle">
                        {<Counter before="+" start={100} end={350} after="k" />}
                    </h3>
                    <span className="counterText">
                        <span className="first">
                            {t('home.mapa.second_child_evaluations')} <br /> {t('home.mapa.second_child_done')} <br />
                        </span>
                        <span className="second">
                            {t('home.mapa.second_child_description')}
                        </span>
                    </span>
                </span>
            </article>

        </section>
    )
}